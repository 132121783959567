import React from 'react';
import { IPage } from '../IMenuSettings';
import { IImageModuleProps, ImageModule, IMenuCategoryModuleProps, MenuCategoryModule } from '../Modules';
import { BackgroundModule } from '../Modules/BackgroundImageModule';

export const Page = (page: IPage) => {
    return (
      <>
        <BackgroundModule data={page.background} />
        {page.items.map((i) => {
            if (i.type === 'image') {
              const props = {
                src: i.src,
                mode: i.mode,
              } as IImageModuleProps;
              return ImageModule(props);
            }
            else if (i.type === 'menu-category') {
              const props = {
                code: i.categoryCode
              } as IMenuCategoryModuleProps;
              return MenuCategoryModule(props);
            } else {
              return "";
            }
          }
        )}
      </>
    )
}