import { IFont } from "../IMenuSettings";

export const Fonts = (fonts: IFont[]) => {
    return(
        fonts.map((font) => {
            let fontUrl = font.url;        
            if (font.isGoogleFont) {
              fontUrl = `https://fonts.googleapis.com/css2?family=${font.urlFamily}:wght@${font.weight}&display=swap`;
            }
            return <link href={fontUrl} rel="stylesheet" />
          })
    );
}