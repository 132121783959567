import { useEffect, useState } from 'react';
import './App.css';
import { Page } from './Components';
import { Fonts } from './Components/Fonts';
import { IMenuSettings, IPage } from './IMenuSettings';
import { useInterval } from 'usehooks-ts'

const settings = require("./settings.json");
const menuSettings: IMenuSettings = require("./data/sources/abc123.json");

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<IPage | undefined>(undefined);
  const [lastPageSwap, setLastPageSwap] = useState<number>(0);

  useEffect(() => {
    loadSettings("abc123");
  },[]);

  useInterval(() => {
    const currentTime = (new Date()).getTime(); 
    if (menuSettings)
    {
      var schema = menuSettings.schemas[0];
      if (schema && schema.type === 'rotate')
      {
        if (!currentPage || (currentTime >= (schema.intervalSec*1000) + lastPageSwap)) {
          const nextPageId = getNextPageNumber(currentPage, schema.pages);
          const nextPage = menuSettings.pages.find(p => p.id === nextPageId);
          setCurrentPage(nextPage);
          setLastPageSwap(currentTime)
        }    
      }
    }
  }, 1000);

  const getNextPageNumber = (currentPage: IPage | undefined, pageList: number[]) => {
    if (!currentPage) return pageList[0];
    for (let i = 0; i <= pageList.length; i++)
    {
      if (pageList[i] === currentPage.id && i < pageList.length-1)
      {
        return pageList[i+1];
      }
    }
    return pageList[0];
  }

  const loadSettings = (code: string) => {
    setIsLoading(true);
    fetch(settings.url, {method: "GET", mode: 'cors'})
      .then(res => res.json())
      .then(res => {
        //setMenuSettings(res);
        setIsLoading(false);
      });
  }

  return (
    <div className="App">
      {Fonts(menuSettings.fonts)} 
      {currentPage && Page(currentPage)}     
    </div>
  );
}

export default App;
