import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { IBackground } from '../IMenuSettings';

interface IBackgroundModuleProps {
    data: IBackground;
}

export const BackgroundModule = (props: IBackgroundModuleProps) => {
    const style = {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,       
        backgroundColor: props.data.color || '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    } as CSSProperties;

    if (props.data.imageUrl) {
        style.backgroundImage = `url(${props.data.imageUrl})`;
    }
    return (
        <div style={style}>
            <DimLayer opacity={props.data.opacity}/>
        </div>
    );
}

interface IDimLayer {
    opacity: number;
}

const DimLayer = styled.div<IDimLayer>`
    height: 100vh;
    width: 100%;
    position: absolute;
    opacity: ${(p) => p.opacity};
    background-color: #fff;
`;