import React, { CSSProperties } from 'react';

export interface IImageModuleProps {
    src: string;
}

export const ImageModule = (props: IImageModuleProps) => {
    const style = {
        
    } as CSSProperties;
    return (
        <div>
            <img src={props.src} style={style} />
        </div>
    );
}